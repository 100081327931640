<template>
  <div>
    <div>
      <base-header class="pb-6">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="text-white d-inline-block mb-0"></h6>
          </div>
        </div>
      </base-header>
      <div class="container-fluid mt--8">
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-header mt--4 mb--4">
                <div class="row">
                  <div class="col">
                    <h3 class="mb-0">{{ tt("abc_analysis") }}</h3>
                  </div>
                  <div class="col-6">
                    <base-input
                      input-classes="form-control-sm text-center border-radius-20"
                      :placeholder="tt('search')"
                    />
                  </div>
                  <div class="col"></div>
                </div>
              </div>

              <div>
                <el-table
                  :data="analysisData"
                  v-if="!loadTable.AbcAnalysis"
                  :header-cell-style="tableHeaderColor"
                  height="400px"
                  border
                >
                  <!-- COMPANY -->
                  <el-table-column
                    :label="tt('company')"
                    sortable
                    width="200px"
                    prop="company_code"
                    align="center"
                  >
                  </el-table-column>

                  <!-- MATERIAL NUMBER -->
                  <el-table-column
                    :label="tt('material_number')"
                    sortable
                    width="200px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.material_number"></el-input>
                    </template>
                  </el-table-column>

                  <!-- SUPPLIER -->
                  <el-table-column
                    :label="tt('supplier')"
                    sortable
                    min-width="200px"
                    prop="supplier"
                    align="center"
                  >
                  </el-table-column>

                  <!-- MATERIAL GROUP -->
                  <el-table-column
                    :label="tt('material_group')"
                    sortable
                    min-width="200px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.dataMaterial.material_group"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <!-- MANUFACTURER -->
                  <el-table-column
                    :label="tt('manufacturer')"
                    sortable
                    min-width="200px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.manufacturer"></el-input>
                    </template>
                  </el-table-column>

                  <!-- MATERIAL DESCRIPTION SHORT -->
                  <el-table-column
                    :label="tt('short_description')"
                    sortable
                    min-width="200px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.dataMaterial.short_description"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <!-- MATERIAL DESCRIPTION LONG -->
                  <el-table-column
                    :label="tt('long_description')"
                    sortable
                    min-width="200px"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-input
                        v-model="scope.row.dataMaterial.long_description"
                      ></el-input>
                    </template>
                  </el-table-column>

                  <!-- SECTION -->
                  <el-table-column
                    :label="tt('section')"
                    sortable
                    min-width="200px"
                    prop="section"
                    align="center"
                  >
                  </el-table-column>

                  <!-- MRP TYPE -->
                  <el-table-column
                    :label="tt('mrp_type')"
                    sortable
                    min-width="200px"
                    prop="mrp_type"
                    align="center"
                  >
                  </el-table-column>

                  <!-- MATERIAL CATEGORY -->
                  <el-table-column :label="tt('material_category')">
                    <el-table-column
                      label="Insurance Operating Consumable"
                      sortable
                      min-width="200px"
                      prop="material_category"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- MPI -->
                  <el-table-column
                    :label="tt('mpi')"
                    sortable
                    min-width="200px"
                    prop="mpi"
                    align="center"
                  >
                  </el-table-column>

                  <!-- CRITICALITY CRITERIA -->
                  <el-table-column :label="tt('abc_criticality')">
                    <el-table-column
                      :label="tt('abc_criticality')"
                      sortable
                      min-width="200px"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          v-model="scope.row.criteria_criticality"
                        ></el-input>
                      </template>
                    </el-table-column>
                  </el-table-column>

                  <!-- ASSET -->
                  <el-table-column
                    :label="tt('asset')"
                    sortable
                    min-width="200px"
                    prop="asset"
                    align="center"
                  >
                  </el-table-column>

                  <!-- LEAD TIME -->
                  <el-table-column
                    label="Lead Time"
                    align="center"
                    :header-align="center"
                  >
                    <el-table-column
                      :label="tt('market')"
                      sortable
                      min-width="200px"
                      prop="market"
                      align="center"
                      header-align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('total_process')"
                      sortable
                      min-width="200px"
                      prop="sum_of_process"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('int_lead_time')"
                      sortable
                      min-width="200px"
                      prop="sum_of_internal_lead_time"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('avg_lead_time_int')"
                      sortable
                      min-width="200px"
                      prop="internal_lead_time"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('int_lead_time_an')"
                      sortable
                      min-width="200px"
                      prop="int_lead_time_an"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('ext_lead_time')"
                      sortable
                      min-width="200px"
                      prop="sum_of_external_lead_time"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('avg_lead_time_eks')"
                      sortable
                      min-width="200px"
                      prop="external_lead_time"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('total_lead_time_day')"
                      sortable
                      min-width="200px"
                      prop="total_lead_time"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('total_lead_time_month')"
                      sortable
                      min-width="200px"
                      prop="total_lead_time_month"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- AVAILIBILITY CRITERIA -->
                  <el-table-column :label="tt('availibility_criteria')">
                    <el-table-column
                      :label="tt('a_criteria')"
                      sortable
                      min-width="200px"
                      prop="criteria_lead"
                      header-align="center"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- AVERAGE UNIT PRICE -->
                  <el-table-column :label="tt('from_mrp')">
                    <el-table-column
                      :label="tt('avg_unit_price')"
                      sortable
                      min-width="200px"
                      prop="avg_of_price"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- UNIT PRICE -->
                  <el-table-column :label="tt('unit_price')" align="center">
                    <el-table-column
                      :label="tt('price')"
                      sortable
                      min-width="200px"
                      prop="price"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('currency')"
                      sortable
                      min-width="200px"
                      prop="currency"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- USAGE / YEAR -->
                  <el-table-column
                    :label="tt('usage_year')"
                    sortable
                    min-width="200px"
                    prop="usage_year"
                    align="center"
                  >
                  </el-table-column>

                  <!-- UOM -->
                  <el-table-column
                    :label="tt('uom')"
                    sortable
                    min-width="200px"
                    prop="uom"
                    align="center"
                  >
                  </el-table-column>

                  <!-- ANALISA PEMAKAIAN MATERIAL -->
                  <el-table-column
                    :label="tt('analisa_material')"
                    scope="header"
                  >
                    <el-table-column
                      :label="tt('total_needed_material')"
                      sortable
                      min-width="200px"
                      prop="sum_of_material"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('avg_needed_material')"
                      sortable
                      min-width="200px"
                      prop="average_by_year"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('annual_usage_price')"
                      sortable
                      min-width="200px"
                      prop="annual_usage"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('pareto')"
                      sortable
                      min-width="200px"
                      prop="pareto"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('pareto_percentage')"
                      sortable
                      min-width="200px"
                      prop="pareto_percentage"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      :label="tt('soh')"
                      sortable
                      min-width="200px"
                      prop="soh"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- USAGE VALUE -->
                  <el-table-column :label="tt('usage_value')">
                    <el-table-column
                      :label="tt('a_b_c_usage_value')"
                      sortable
                      min-width="200px"
                      prop="criteria_usage_value"
                      align="center"
                    >
                    </el-table-column>
                  </el-table-column>

                  <!-- ABC ANALYSIS -->
                  <el-table-column
                    :label="tt('abc_analysis')"
                    sortable
                    min-width="200px"
                    prop="abc_analysis"
                    align="center"
                  >
                  </el-table-column>

                  <!-- Z -->
                  <el-table-column
                    :label="tt('z')"
                    sortable
                    min-width="200px"
                    prop="service_level.z"
                    align="center"
                  >
                  </el-table-column>

                  <!-- AVG DEMAND -->
                  <el-table-column
                    :label="tt('avg_demand')"
                    sortable
                    min-width="200px"
                    prop="average_demand"
                    align="center"
                  >
                  </el-table-column>

                  <!-- AVG LT -->
                  <el-table-column
                    :label="tt('avg_lt')"
                    sortable
                    min-width="200px"
                    prop="average_lead_time"
                    align="center"
                  >
                  </el-table-column>

                  <!-- STD -->
                  <el-table-column
                    :label="tt('std')"
                    sortable
                    min-width="200px"
                    prop="std"
                    align="center"
                  >
                  </el-table-column>

                  <!-- STL -->
                  <el-table-column
                    :label="tt('stl')"
                    sortable
                    min-width="200px"
                    prop="stl"
                    align="center"
                  >
                  </el-table-column>

                  <!-- SAFETY STOCK -->
                  <el-table-column
                    :label="tt('safety_stock')"
                    sortable
                    min-width="200px"
                    prop="safety_stock"
                    align="center"
                  >
                  </el-table-column>

                  <!-- ROP -->
                  <el-table-column
                    :label="tt('rop')"
                    sortable
                    min-width="200px"
                    prop="rop"
                    align="center"
                  >
                  </el-table-column>

                  <!-- ANNUAL DEMAND -->
                  <el-table-column
                    :label="tt('annual_demand')"
                    sortable
                    min-width="200px"
                    prop="annual_demand"
                    align="center"
                  >
                  </el-table-column>

                  <!-- K -->
                  <el-table-column
                    :label="tt('k')"
                    sortable
                    min-width="200px"
                    prop="service_level.order_cost"
                    align="center"
                  >
                  </el-table-column>

                  <!-- I -->
                  <el-table-column
                    :label="tt('i')"
                    sortable
                    min-width="200px"
                    prop="service_level.holding_cost"
                    align="center"
                  >
                  </el-table-column>

                  <!-- UNIT COST -->
                  <el-table-column
                    :label="tt('unit_cost')"
                    sortable
                    min-width="200px"
                    prop="unit_cost"
                    align="center"
                  >
                  </el-table-column>

                  <!-- EOQ -->
                  <el-table-column
                    :label="tt('eoq')"
                    sortable
                    min-width="200px"
                    prop="eoq"
                    align="center"
                  >
                  </el-table-column>

                  <!-- VALUE SAFETY STOCK -->
                  <el-table-column
                    :label="tt('ss_value')"
                    sortable
                    min-width="200px"
                    prop="value_safety_stock"
                    align="center"
                  >
                  </el-table-column>

                  <!-- VALUE EOQ -->
                  <el-table-column
                    :label="tt('eoq_value')"
                    sortable
                    min-width="200px"
                    prop="value_eoq"
                    align="center"
                  >
                  </el-table-column>

                  <!-- AVG INVENTORY -->
                  <el-table-column
                    :label="tt('avg_inventory')"
                    sortable
                    min-width="200px"
                    prop="average_inventory"
                    align="center"
                  >
                  </el-table-column>

                  <!-- PEAK INVENTORY -->
                  <el-table-column
                    :label="tt('peak_inventory')"
                    sortable
                    min-width="200px"
                    prop="peak_inventory"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
                <skeleton-loading v-else />
              </div>

              <div class="card-footer">
                <custom-pagination
                  :page-count="table.page.last_page"
                  v-model="table.page.current_page"
                  @click.native="changePage(table.page.current_page)"
                  size="sm"
                ></custom-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/helpers/api";
import ABCAnalysisService from "@/services/rcs/abc/analysis.service";

export default {
  data() {
    return {
      analysisData: [],

      load: {
        abcAnalysis: false,
      },
      loadTable: {
        abcAnalysis: false,
      },
      table: {
        page: {
          last_page: null,
        },
      },
    };
  },
  mounted() {
    this.getABCAnalysis();
  },
  methods: {
    getABCAnalysis() {
      let context = this;
      this.loadTable.abcAnalysis = true;
      Api(context, ABCAnalysisService.getABCAnalysis())
        .onSuccess((response) => {
          context.analysisData = response.data.data;
          context.loadTable.abcAnalysis = false;
        })
        .onError((err) => {
          context.analysisData = [];
          context.loadTable.abcAnalysis = false;
        })
        .call();
    },

    tableHeaderColor({ column, columnIndex }) {
      if (columnIndex <= 10 && column.level == 1) {
        return "background-color: darkblue;color: #fff;font-weight: 500;";
      } else if (columnIndex === 0 && column.level == 2) {
        return "background-color: darkblue;color: #fff;font-weight: 500;";
      } else if (columnIndex === 11 && column.level == 1) {
        return "background-color: red;color: #fff;font-weight: 500;";
      } else if (columnIndex === 1 && column.level == 2) {
        return "background-color: red;color: #fff;font-weight: 500;";
      } else if (columnIndex === 12 && column.level == 1) {
        return "background-color: bisque;color: #000000;font-weight: 500;";
      } else if (columnIndex === 13 && column.level == 1) {
        return "background-color: palegreen;color: #000000;font-weight: 500;";
      } else if (columnIndex >= 2 && columnIndex <= 10 && column.level == 2) {
        return "background-color: palegreen;color: #000000;font-weight: 500;";
      } else if (columnIndex === 14 && column.level == 1) {
        return "background-color: yellow;color: #000000;font-weight: 500;";
      } else if (columnIndex === 11 && column.level == 2) {
        return "background-color: yellow;color: #000000;font-weight: 500;";
      } else if (columnIndex >= 15 && columnIndex <= 19 && column.level == 1) {
        return "background-color: dodgerblue;color: #fff;font-weight: 500;";
      } else if (columnIndex >= 12 && columnIndex <= 20 && column.level == 2) {
        return "background-color: dodgerblue;color: #fff;font-weight: 500;";
      } else if (columnIndex == 20 && column.level == 1) {
        return "background-color: orange;color: #000000;font-weight: 500;";
      } else if (columnIndex == 21 && column.level == 2) {
        return "background-color: orange;color: #000000;font-weight: 500;";
      } else {
        return "background-color: black;color: #fff;font-weight: 500;";
      }
    },
  },
};
</script>

<style></style>
